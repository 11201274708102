h1,
.h1 {
  line-height: 56px;
  font-size: 44px;
  margin-bottom: 0;
  font-weight: 500;
}

h2,
.h2 {
  line-height: 54px;
  font-size: 38px;
  margin-bottom: 0;
  font-weight: 500;
}

h3,
.h3 {
  line-height: 42px;
  font-size: 30px;
  margin-bottom: 0;
  font-weight: 500;
}

h4,
.h4 {
  line-height: 36px;
  font-size: 26px;
  margin-bottom: 0;
  font-weight: 500;
}

h5,
.h5 {
  line-height: 30px;
  font-size: 23px;
  margin-bottom: 0;
  font-weight: 500;
}

h6,
.h6 {
  line-height: 28px;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
}

.subtitle1 {
  line-height: 24px;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}

.subtitle2 {
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
}

.body1 {
  line-height: 16px;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}

.body2 {
  line-height: 14px;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
}
