.root {
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  text-align: var(--text-align);
  text-transform: var(--text-transform);
  font-family: var(--font-family);
  color: var(--text-color, var(--color-text-default));
}

@screen desk {
  .root {
    font-size: var(--font-size_desk, var(--font-size));
    font-weight: var(--font-weight_desk, var(--font-weight));
    line-height: var(--line-height_desk, var(--line-height));
    text-align: var(--text-align_desk, var(--text-align));
    text-transform: var(--text-transform_desk, var(--text-transform));
    font-family: var(--font-family_desk, var(--font-family));
    color: var(--text-color_desk, var(--text-color, var(--color-text-default)));
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
