.root {
  --btn-text-case: none;
}

.disabled[disabled] {
  --tw-border-opacity: 0.7;
  --tw-bg-opacity: 0.7;
  --tw-text-opacity: 1;
}

.primaryDisabled[disabled] {
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.secondaryDisabled[disabled] {
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.successDisabled[disabled] {
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.errorDisabled[disabled] {
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.infoDisabled[disabled] {
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.warningDisabled[disabled] {
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.transparentLight {
}

.transparentLight:hover {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
